@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,400i,700&display=swap");
.row--bottom {
  padding-bottom: 38px; /* space for chat tab at bottom */
}

footer #footer__usps {
  padding: 15px;
  background: #fff;
  box-shadow: 0 0px 60px 0 rgba(0, 0, 0, 0.22);
  position: relative;
}
footer #footer__usps a {
  color: #014268;
}
footer #footer__usps .fa {
  color: #ddd100;
}
footer #ondermenu {
  padding: 40px 0 20px 0;
  background: #014268;
  color: #fff;
}
footer #ondermenu .row--cols > div {
  margin-bottom: 20px;
}
footer #ondermenu .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
footer #ondermenu .footer__widget ul li {
  display: table-row;
}
footer #ondermenu .footer__widget ul li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #ddd100;
}
footer #ondermenu .widget__title {
  border-bottom: 3px solid #346886;
  margin-bottom: 10px;
}
footer #ondermenu .widget__title h5 {
  border-bottom: 3px solid #fff;
  padding-bottom: 10px;
  margin-bottom: -3px;
  display: inline-block;
}
footer #bottommenu {
  background: #013a5c;
  padding: 20px 0;
  color: #fff;
}
footer .bottomnav ul {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
}
footer .bottomnav ul li {
  display: inline-block;
}
footer .bottomnav ul li + li:before {
  content: "-";
  padding: 0 6px;
  display: inline-block;
}
footer h5 {
  color: #fff;
  display: block;
  margin: 0 0 10px;
  padding: 0 0 10px;
}
footer a, footer h4 {
  color: #fff;
  text-decoration: none;
}
footer a:hover,
footer a:focus {
  color: #fff;
  text-decoration: underline;
}
footer .form-control {
  border: 0;
}
footer #mc_embed_signup form {
  padding: 0;
}
footer #mc_embed_signup div.response {
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  padding: 0 10px;
}
footer #mc_embed_signup div#mce-responses {
  padding: 0;
  margin: 0;
  background: rgba(232, 92, 65, 0.3);
  color: rgb(232, 92, 65);
  margin-bottom: 5px;
  border-radius: 3px;
}
footer #mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
  background: rgba(232, 92, 65, 0.3);
  margin-bottom: 0;
  margin-top: 10px;
}
footer #mc_embed_signup input {
  border: 0px;
}