.page--homepage .container.site__content {
  width: 100%;
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.page--homepage #product_listing,
.page--homepage .widget-fullwidthimage {
  max-width: 1340px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
}
@media (max-width: 576px) {
  .page--homepage #product_listing,
  .page--homepage .widget-fullwidthimage {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.page--homepage .twocol:before,
.page--homepage #trustpilot__widget:before {
  position: absolute;
  content: "";
  top: 0;
  left: -1000%;
  width: 2000%;
  height: 100%;
  z-index: -1;
  background-color: #faf9ed;
}

.page--homepage .twocol,
.page--homepage #trustpilot__widget {
  position: relative;
  z-index: 0;
  background-color: #faf9ed;
  padding: 50px 15px;
}

.page--homepage .wh-rtd__img {
  margin: 0 auto;
  display: flex;
}