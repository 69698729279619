@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,400i,700&display=swap");
.wh-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0 none;
}
.wh-pagination li {
  display: inline-block;
  border: 1px solid #ddd;
  text-align: center;
  border-radius: 3px;
}
.wh-pagination li + li {
  margin-left: 5px;
}
.wh-pagination span, .wh-pagination a {
  display: block;
  padding: 5px 12px;
}
.wh-pagination a {
  color: #0273b5;
  text-decoration: none;
}
.wh-pagination a:hover {
  background-color: #eee;
}
.wh-pagination li.active {
  border: 1px solid #0273b5;
}
.wh-pagination li.active a {
  background-color: #0273b5;
  color: #fff;
}
.wh-pagination span::before {
  content: "...";
  display: inline-block;
}