/* light, normal, semi-bold, bold */
html, 
body
{
  color: #014268;
  font-family: 'Source Sans Pro', sans-serif;
}

body
{
  font-size: 16px;
  line-height: 24px;
}

h1, h2, h3, h4, h5, h6,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a{
    color: #014268;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight:600;
    margin-bottom:1rem;
}
h1:after {
    content: ' ';
    display: block;
    border-bottom: 4px solid #ddd100;
    padding-bottom: 10px;
    width: 100px;
}
h1.heading1_centered,
h2.heading2_centered,
h3.heading3_centered {
  text-align: center;
}

h1.heading1_centered:after {
  margin:0 auto;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}
a {
    color: #337ab7;
}
ul.unordered,
ol.ordered  {
  margin-bottom:20px;
  padding: 0;
}
ul.unordered li,
ol.ordered li{
  margin-left: 25px;
}
ul.checkmarks {
    padding: 0;
    display: table;
    list-style: none;
}
ul.checkmarks li {
    display: table-row;
}
ul.checkmarks li:before {
    content: '\f00c';
    width: 22px;
    font-family: FontAwesome;
    display: table-cell;
    color: #96c11f;
}
.button a,
.button_centered a{
    display: inline-block;
    width: auto;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    background-color: #7fb204;
    border-color: #7fb204;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.button a:hover,
.button a:focus,
.button a:active,
.button a.active,
.button a.active:hover,
.button_centered a:hover,
.button_centered a:focus,
.button_centered a:active,
.button_centered a.active,
.button_centered a.active:hover   {
  background-color: #90cc04;
  border-color: #90cc04;
  text-decoration: none;
}
.button_centered a {
    width: max-content;
    margin: 0 auto;
    display: block;
}
sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}
.centered {
  text-align: center;
}
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}
.wh-rtd__table .wh-rtd__img
{
  max-width: calc(100vw - 40px);
}
.wh-rtd__img--floatleft
{
  margin: 0 15px 15px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 15px 15px;
}

.wh-rtd__tablewrap
{
  overflow-x: auto;
  padding-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table
{
  width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  border-top: 0;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  padding-left: 15px;
  background-color: #584a45;
}
.wh-rtd--hascolheader ~ tr > td:first-child
{
  padding-left: 15px;
}

.wh-rtd__table * + *
{
  padding-left: 15px;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding-top: 5px;
  padding-bottom: 5px;
}
  .wh-rtd__table th > p:last-child
, .wh-rtd__table td > p:last-child
{
  padding-bottom: 0;
}
/*
.wh-rtd__table tr + tr > td
{
  border-top: 1px solid #dadada;
}
*/
.wh-rtd__table .wh-rtd--hascolheader + tr > td
{
  border-top: 0 none;
}
