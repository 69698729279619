@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,400i,700&display=swap");
html.wh-wrdauth--isloggedin .show-if-loggedout {
  display: none;
}

html:not(.wh-wrdauth--isloggedin) .show-if-loggedin {
  display: none;
}

header {
  position: relative;
  background-color: #fff;
}
header:after {
  clear: both;
  display: block;
  content: "";
}
header .logo {
  display: inline-block;
  margin: 5px 0;
  height: 100px;
  float: left;
}
header .logo > img {
  height: 100%;
  width: auto;
}
header .topusps {
  float: right;
  width: 280px;
  margin-bottom: 10px;
  margin-top: 17px;
}
header .topusps a {
  color: #014268;
  text-decoration: none;
}
header .topusps ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
header .topusps i {
  color: #ddd100;
}
header .toprightpanel {
  float: right;
  width: 360px;
  background-color: #fff;
  position: relative;
  padding: 20px;
  display: flex;
}
header .toprightpanel:before {
  content: "";
  display: block;
  width: 20px;
  height: 115px;
  position: absolute;
  right: 100%;
  top: 0;
  background: url(../web/img/toprightpanel_shadow.png) no-repeat;
}
header .toprightpanel a {
  color: inherit;
  text-decoration: none;
}
header .toprightpanel > a {
  display: block;
  flex: 0 0 95px;
  text-align: center;
  margin-top: 10px;
  position: relative;
}
header .toprightpanel > a .badge {
  background: #e0d003;
  position: absolute;
  margin-left: -10px;
  top: -5px;
  right: 20px;
  font-size: 80%;
  border-radius: 20px;
}
header .toprightpanel > a .service-icon,
header .toprightpanel > a .user-icon,
header .toprightpanel > a .cart-icon {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0 auto;
}
header .toprightpanel > a .service-icon {
  background-image: url("../web/img/service.svg");
}
header .toprightpanel > a .user-icon {
  background-image: url("../web/img/user.svg");
}
header .toprightpanel > a .cart-icon {
  background-image: url("../web/img/cart.svg");
}
@media (max-width: 991px) {
  header .topusps a {
    font-size: 14px;
  }
  header .toprightpanel {
    border-right: 0 none;
    border-left: 0 none;
    background-color: transparent;
    justify-content: flex-end;
    max-width: 150px;
    padding-right: 0;
    padding-bottom: 0;
    height: 75px;
  }
  header .toprightpanel > a {
    flex: 0 0 65px;
    font-size: 12px;
  }
  header .toprightpanel:before,
  header .toprightpanel .topnav {
    display: none;
  }
  header .toprightpanel a .badge {
    top: -5px;
    right: 7px;
  }
}
@media (max-width: 767px) {
  header .logo {
    height: 80px;
  }
  header .topusps {
    margin-top: 0;
    width: 100%;
    text-align: center;
  }
  header .toprightpanel {
    height: 65px;
    padding-top: 15px;
  }
  header .toprightpanel a {
    font-size: 12px;
  }
}
@media (max-width: 565px) {
  header .topusps {
    margin-top: 0;
    width: 100%;
    text-align: center;
  }
}

.navbar-dark {
  clear: both;
  background: #014268;
}

.navbar-brand {
  font-size: 1rem;
  margin-left: 1rem;
}

.navbar-toggler {
  border: none;
}

#mainnav {
  width: 100%;
}
#mainnav .toplevelmenu__container {
  width: 100%;
}
#mainnav .toplevelmenu__container .nav-link {
  color: #fff;
  font-weight: 600;
  padding: 1rem 1.5rem;
}
#mainnav .toplevelmenu__container .nav-link i {
  color: #fff;
}
#mainnav .toplevelmenu__container .toplevelmenu {
  margin: 0 auto;
  padding: 0;
  position: relative;
  z-index: 10;
}
#mainnav .toplevelmenu__container .toplevelmenu .dropdown-menu {
  border-radius: 0;
  border: 0;
  min-width: 750px;
  padding: 0;
  margin: 0;
}
#mainnav .toplevelmenu__container .toplevelmenu__item:hover > a, #mainnav .toplevelmenu__container .toplevelmenu__item:focus > a {
  background-color: #015382;
}
@media (min-width: 1200px) {
  #mainnav .toplevelmenu__container .toplevelmenu__item.dropdown:hover > .dropdown-menu {
    display: block;
  }
}
#mainnav .toplevelmenu__container .toplevelmenu .dropdown-toggle::after {
  content: "\f107";
  font-family: "FontAwesome";
  font-weight: 900;
  border: 0;
  font-size: 14px;
  position: relative;
  top: 4px;
}
#mainnav .toplevelmenu__container li {
  display: inline-block;
}
#mainnav .toplevelmenu__container li a {
  color: #fff;
}
#mainnav .toplevelmenu__container li:hover li a:hover {
  text-decoration: underline;
}
#mainnav .dropdown-menu {
  position: absolute;
  width: 100%;
  padding: 1rem 1rem 2rem 1rem;
  margin: -1px 0 0 0;
  background-color: #fff;
}
#mainnav .dropdown-menu p.normal {
  display: none;
}
#mainnav .dropdown-menu ul {
  padding-left: 0;
}
#mainnav .dropdown-menu ul li {
  display: block;
  color: #014268;
  position: relative;
  margin-left: 0;
  padding: 4px 0px;
}
#mainnav .dropdown-menu ul li a {
  text-decoration: none;
  color: #014268;
}
#mainnav .dropdown-menu ul li strong, #mainnav .dropdown-menu ul li b {
  font-size: 18px;
}
@media (min-width: 1200px) and (max-width: 1400px) {
  #mainnav .dropdown-menu-xl-left {
    right: -50%;
    left: -100%;
    margin-left: -50% !important;
    margin-right: -50% !important;
  }
}
#mainnav #topsearchform {
  position: absolute;
  right: 30px;
  top: 30px;
  -webkit-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  max-width: 250px;
  overflow: hidden;
}
#mainnav #topsearchform * {
  outline: none;
  box-sizing: border-box;
}
#mainnav #topsearchform .search__wrapper {
  position: relative;
  top: -1px;
}
#mainnav #topsearchform .search__wrapper .search__field {
  width: 36px;
  height: 36px;
  color: transparent;
  padding: 0.35em 30px 0.35em 0;
  border: 1px solid transparent;
  border-radius: 0;
  cursor: pointer;
  background-color: #014268;
  transition: all 0.3s ease-in-out;
}
#mainnav #topsearchform .search__wrapper .search__field:focus {
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  background-color: #fff;
  width: 30vw;
  padding-left: 5px;
  color: #014268;
  cursor: default;
}
#mainnav #topsearchform .search__wrapper .search__field:focus ~ .search__icon {
  color: #014268;
  cursor: pointer;
  pointer-events: auto;
}
#mainnav #topsearchform .search__wrapper .search__field::-webkit-input-placeholder, #mainnav #topsearchform .search__wrapper .search__field:-moz-placeholder, #mainnav #topsearchform .search__wrapper .search__field:-ms-input-placeholder {
  position: relative;
  top: 0;
  left: 0;
  transition-property: top, color;
  transition-duration: 0.1s;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  opacity: 1;
}
#mainnav #topsearchform .search__wrapper .search__field::-webkit-input-placeholder[style*=hidden], #mainnav #topsearchform .search__wrapper .search__field:-moz-placeholder[style*=hidden], #mainnav #topsearchform .search__wrapper .search__field:-ms-input-placeholder[style*=hidden] {
  color: #000;
  font-size: 0.65em;
  font-weight: normal;
  top: -20px;
  opacity: 1;
  visibility: visible !important;
}
#mainnav #topsearchform .search__wrapper .search__icon {
  position: absolute;
  background-color: transparent;
  top: 0;
  color: #fff;
  right: 0;
  width: 36px;
  height: 36px;
  text-align: center;
  border-color: transparent;
  border-radius: 50%;
  pointer-events: none;
  display: inline-block;
}
#mainnav #topsearchform .search__wrapper .search__icon i {
  font-size: 22px;
}