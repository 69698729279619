.widget
{
  margin-bottom: 1rem;
}
  .widget--mobile
, .widget--desktop
{
  display: none;
}
@media(max-width: 767px)
{
  .widget--mobile
  {
    display: block;
  }
}
@media(min-width: 768px)
{
  .widget--desktop
  {
    display: block;
  }
}


/**/
.sidereview .rating-stars-count
{
  padding: 5px 10px;
  display: block;
}
.sidereview .ratingcomment
{
  clear: both;
  margin-top: 5px;
  background-color: #eee;
  position: relative;
}
.sidereview .ratingcomment:after
{
  content: "";
  display: block;
  position: absolute;
  left: 15px;
  top: 100%;
  border-top: 10px solid #eee;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
}
.sidereview .ratingcomment > *
{
  padding: 15px 10px;
}

/**/
.widget-inlinemultiplereviews--bgwhite
{
  background-color: #fff;
  padding: 20px;
}

/**/
.widget-inlinereview__comment
{
  background-color: #f8f9fa;
  border-radius: 2px;
  box-shadow: 0 1px 0 0 rgba(182,196,210,.4);
  padding: 10px;
  position: relative;
  margin-bottom: 15px;
}
.widget-inlinereview__comment > *
{
  display: inline-block;
  margin: 0;
}
.widget-inlinereview__meta b
{
  padding-right: 10px;
}
.widget-inlinereview__more
{
  margin-top: 10px;
  font-weight: 600;
}

/**/
.widget-fullwidthimage > img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

/* widget faq */
.widget-faq .card
{
  margin-bottom: 10px;
  border: 0;
  box-shadow: 0 2px 3px rgba(0,0,0,0.1);
}
.widget-faq .card-header
{
  background-color: #faf9ed;
}

.widget-faq .card .card-header
{
  padding:0 5px;
  margin-bottom: 0;
}
.widget-faq .btn-link
{
  color:#014268;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
  font-weight: 600;
}
.widget-faq .card-header .btn:after
{
  font-family: 'FontAwesome';
  content: "\f068";
  float: right;
  font-size: 14px;
}
.widget-faq .card-header .btn.collapsed:after
{
  content: "\f067";
}

/* widget collapse */
.widget-collapse .card
{
  margin-bottom: 10px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, .125);
}
.widget-collapse .card-header
{
  background-color: #fff;
  border-radius: 6px;
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding: 5px 10px;

}
.widget-collapse .card-header h4 button:hover
{
  text-decoration: none;
}

.widget-collapse .card .card-header
{
  padding:0 5px;
  margin-bottom: 0;

}
.widget-collapse .btn-link
{
  color:#014268;
  display: block;
  width: 100%;
  text-align: left;
  padding: .5rem;
  font-weight: 600;
  text-decoration: none;
}
.widget-collapse .btn-link:focus
{
  box-shadow: none;
}
.widget-collapse .card-header .btn[aria-expanded="false"]:after
{
  font-family: 'FontAwesome';
  content: "\f068";
  font-size: 16px;
  color: #014268;
  position: absolute;
  right: 15px;
}
.widget-collapse .card-header .btn[aria-expanded="true"]:after
{
  font-family: 'FontAwesome';
  content: "\f067";
  font-size: 16px;
  color: #014268;
  position: absolute;
  right: 15px;
}

.widget-tagcloud
{
  position: relative;
  z-index: 1;
}
.widget-tagcloud ul
{
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
.widget-tagcloud li
{
  display: table-row;
}
.widget-tagcloud li a
{
  text-decoration:none;
}
.widget-tagcloud li:before
{
  content: '\f105';
  width: 1.5em;
  font-size: 16px;
  line-height: 24px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #ddd100;
}
.widget-tagcloud li.showmore:before
{
  color: transparent;
}
.widget-tagcloud .showmore
{
  cursor: pointer;
  position: relative;
  top: 10px;
}
/**/
.widget-chatbanner
{
  margin: 20px 0 20px;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 0 7px rgba(0,0,0,.2);
  -moz-box-shadow: 0 0 7px rgba(0,0,0,.2);
  -webkit-box-shadow: 0 0 7px rgba(0,0,0,.2);
  background: url(../web/img/hulp-bg.jpg) no-repeat 100% 0 #fff;
  background-size: auto 100%;
  padding-right: 160px;
}

@media( max-width: 992px)
{
  .widget-chatbanner
  {
    padding-right: 20px;
  }
}

@media( max-width: 767px)
{
  .widget-chatbanner
  {
    padding-right: 160px;
  }
}

@media( max-width: 500px)
{
  .widget-chatbanner
  {
    padding-right: 20px;
  }
}

/**/
.widget-categorieslist
{
  list-style-type: none;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 0 15px;
}
.widget-categorieslist li
{
  border: 1px solid #ddd;
  display: block;
  background-color: #fff;
  transition: background-color 0.2s;
}
.widget-categorieslist li + li
{
  margin-top: -1px;
}
.widget-categorieslist li:hover
{
  background-color: #f5f5f5;
}
.widget-categorieslist li:first-child
{
  border-radius: 4px 4px 0 0;
}
.widget-categorieslist li:last-child
{
  border-radius: 0 0 4px 4px;
}
.widget-categorieslist a
{
  display: block;
  color: #555;
  position: relative;
  padding: 10px 15px;
}
.widget-categorieslist a:after
{
  font-family: FontAwesome;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: -10px;
  content: "\f105";
  font-size: 16px;
}
.widget-categorieslist a:hover
{
  text-decoration: none;
}

.ortho-buttongroup
{
  margin-bottom:20px;
}
.ortho-buttongroup a
{
  color: #000;
  font-weight: normal;
  font-size:14px;
  display:block;
}
.ortho-buttongroup.nosplide
{
  visibility:visible;
}
.ortho-buttongroup:not(.nosplide) .splide__track
{
  width: 85%;
  margin: 0 auto;
}
.ortho-buttongroup .splide__arrow--prev
{
  top:50%;
  left:0;
  box-shadow:none;
  border:none;  
}
.ortho-buttongroup .splide__arrow--next
{
  top:50%;
  right:0;
  box-shadow:none;
  border:none;
}
  
.ortho-buttongroup.nosplide .splide__slide
{
  width:auto;
}
.ortho-buttongroup.nosplide .splide__arrows
{
  display:none;
}
.ortho-buttongroup.nosplide a
{
  margin-right:15px;
}
/* contact*/
.widget-contact h4
{
  font-size: 18px;
  font-weight: 600;
}
.widget-contact ul
{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.widget-contact li
{
  border-bottom: 1px solid #ccc;
  padding: 8px 0;
}
  .widget-contact li > a
, .widget-contact li > .jivo-btn
{
  display: block;
  position: relative;
  padding-left: 27px;
  color: #337ab7;
  cursor: pointer;
}
  .widget-contact li > a:hover
, .widget-contact li > .jivo-btn:hover
{
  text-decoration: underline;
}
.widget-contact .fa
{
  position: absolute;
  left: 0;
  right: 0;
  font-size: 19px;
  color: #4dadd8;
}

/* banner */
.widget-image__link
{
  color: #584a45;
}
.widget-image__link:hover
{
  color: inherit;
}
.widget-image img
{
  width: 100%;
  height: auto;
  vertical-align: bottom;
}


.widget-map
{
  padding-top: 75%;
  position: relative;
  width: 100%;
  background-color: #f1f1f1;
  overflow: hidden;
  cursor: pointer;
}
.widget-map:after
{
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.widget-twocol__row__col .widget-map
{
  padding-top: 100%;
}
.widget-map__gmap
{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.widget-map__gmap .wh-infowindow
{
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  width: 40px;
  height: 40px;
  margin-top: -40px;
  margin-left: -20px;
}
.widget-map__gmap .wh-infowindow > *
{
  display: block;
}
.widget-map__gmap .wh-infowindow .title
{
  padding-top: 5px;
  font-size: 16px;
  line-height: 16px;

  width: 300px;
  margin-left: -130px;
}


  .twocol .row:after
, .twocol .col-md-6:after
{
  content: "";
  clear: both;
  display: block;
}

.twocol .col-md-6
{
  width: 50%;
  float: left;
}

@media(max-width: 768px)
{
  .twocol .col-md-6
  {
    padding-right: 0;
    padding-left: 0;
    float: none;
    width: 100%;
  }
}

/* productfilter(s) */
.productsgrid[data-filterconfig="null"] .widget-mobileproductfilter
{
  display:none;
}
.widget-productfilter--hidden
{
  display: none;
}
.productfilter__activelabel
{
  background-color: #fff;
  padding: 5px 25px 5px 10px;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 8px;
  margin-right: 8px;
  display: inline-block;
  position: relative;
}
.productfilter__activeremove:before
{
  content: "x";
  font-size: 18px;
  cursor: pointer;
  font-style: normal;
  color: #d35f5f;
  position: absolute;
  right: 5px;
  top: 3px;
}
#filterSideNav .productfilter + .productfilter
{
  border-top:0;
}

#filterSideNav .widget-productfilter
{
  border-bottom:0;
  border:none!important;
}
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob
{
  color:#333;
  font-size: 14px;
}
#filterSideNav ul li
{
  border-bottom:0!important;
  padding: 6px 0px;
}
.productfilter-title
{
  padding:0;
  margin: 10px 0;
  display: block;
  color:#333;
  font-size: 14px;
  font-weight: 600;
}
.productfilter-title[data-bs-toggle="collapse"]:after
{
  content: "\f106";
  font-family: FontAwesome;
  float: right;
  font-size: 16px;
  position: relative;
  top: -3px;
}

.productfilter-title[data-bs-toggle="collapse"].collapsed:after
{
  content: "\f107";
  font-family: FontAwesome;
  float: right;
  font-size: 18px;
}

.productfilter--optionlist
{
  list-style-type: none;
  margin: -5px 0 0 0;
  padding: 0;
}
.productfilter--optionlist li label
{
  padding-left: 5px;
  margin-bottom: 0px;
  font-size: 14px;
}
.productfilter--select
{
  width: 100%;
  background-color:#fff;
  padding: 5px;
  border-color: #ccc;
  border-radius: .25rem;
  color: #495057;
  font-weight: 300;
}
@media(max-width: 767px)
{
  #sidebar .widget-productfilter,
  .widget-categorylist
  {
    display: none;
  }
}
a.collapsebtn[aria-expanded="false"]:before{
  content:"Filter";
}

a.collapsebtn[aria-expanded="true"]:before{
  content:"Filter inklappen";
}

.categorylist__list,
.subcategorylist__list,
.subsubcategorylist__list
{
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
}
.categorylist__list li a:hover,
.categorylist__list li a:focus
{
  color:#333;
}
.subcategorylist__list,
.subsubcategorylist__list
{
  display: none;
}
.categorylist__listitem,
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  font-size: 15px;
}

.categorylist__listitem
{
  list-style-type: none;
  padding: 1px 0;
}
.categorylist__list > li.categorylist__listitem
{
  display: none;
}
.categorylist__list > ul > li > a 
{
  margin-bottom: 10px;
}
.categorylist__list > li.categorylist__listitem.selected
{
  display: block;
}
.categorylist__list > li.categorylist__listitem.selected > a
{
  font-size: 18px;
  font-weight: 600;
}
.subcategorylist__listitem,
.subsubcategorylist__listitem
{
  list-style-type: none;
  padding: 0 0 0 10px;
}
li.selected > .subcategorylist__list,
li.selected > .subsubcategorylist__list
{
  display: block;
}
.subcategorylist__listitem.selected > a,
.subsubcategorylist__listitem.selected > a
{
  text-decoration:underline;
  font-weight: 600;
}
.categorylist__listitemlink,
.subcategorylist__listitemlink,
.subsubcategorylist__listitemlink
{
  color: #014268;
  text-decoration:none;
}
.featured_categories .p-4
{
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, .125);
}
.featured_categories .p-4 > h1,
.featured_categories .p-4 > h2,
.featured_categories .p-4 > h3
{
  margin-bottom: 0;
  padding-bottom: 0;
}

.category__blocks .card-img-overlay
{
  top: inherit;
}
.category__blocks .card-img-overlay:after 
{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .7));
    opacity: .7;
}
.category__blocks .card-img-overlay:before 
{
    content: '\f105';
    font-size: 21px;
    font-family: FontAwesome;
    color: #fff;
    position: absolute;
    z-index: 1;
    right: 15px;
    bottom: 20px;
}
.category__blocks h3
{
  line-height: inherit;
  font-weight: 300;
}
.category__blocks .categoryblock__title
{
  font-size: 18px;
  position: relative;
  z-index: 1;
  color: #fff;
  text-shadow: 1px 1px 4px rgba(0,0,0,.7);
}

.category__blocks .zoom img {
    transition: all 0.2s linear;
}
.category__blocks .zoom:hover img {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
}

#trustpilot__widget {
  padding:70px 0 100px 0;
  background-color:#faf9ed;
}

.trustpilot__wrapper {
  background-color:#fff;
  padding:20px;
}

.trustpilot__info {
  margin-top:100px;
}
.trustpilot__wrapper  .webshopreviews-widget
{
  max-height:250px;
  overflow-y: auto;
}
@media (max-width: 576px) {
  .trustpilot__info {
      margin-top: 0;
  }
}
@media (max-width: 768px) {
  .trustpilot__info {
      margin-top: 0;
  }
}

.yellowblock,
.twocol.bg-light
{
  position: relative;
  z-index: 0;
  background-color:#faf9ed!important;
  padding: 15px;
  margin:20px 0;
  overflow:hidden;
}
.twocol.bg-white
{
  position: relative;
  z-index: 0;
  background-color:#fff!important;
  padding: 15px;
  margin:20px 0;
  overflow:hidden;
}
:not(.page--homepage) #trustpilot__widget,
:not(.webshop--isproductpage) #trustpilot__widget
{
  position: relative;
  z-index: 0;
  background-color:#faf9ed;
  padding: 50px 15px;
}
:not(.page--homepage) #trustpilot__widget
{
  padding:70px 0 100px;
}
.yellowblock:before,
#trustpilot__widget:before 
{
  position: absolute;
  content: "";
  top:0;
  left: -1000%;
  width: 2000%;
  height: 100%;
  z-index: -1;
  background-color:#faf9ed;
}
.webshop--isproductpage #trustpilot__widget:before 
{
   background-color:#fff;
}
.webshop--isproductpage #trustpilot__widget 
{
  background-color:#fff;
}

.ortho-highlight-products
{
  background-color:#faf9ed;
  border-radius: 0.375rem;
  padding-bottom: 30px!important;
}
.ortho-highlight-products.is-active
{
  padding-bottom: 60px !important;
}
.ortho-highlight-products .splide__slide .productitem
{
  border:1px solid #ddd100;
}
.ortho-highlight-products .splide__slide .productitem .card-body,
.ortho-highlight-products .splide__slide .productitem .card-footer
{
  padding: 1rem;
}
.ortho-highlight-products .splide__slide .productitem__image 
{ 
  margin-left: -16px; margin-right: -16px; 
}
.ortho-highlight-products .splide__slide .productitem__image:before
{
  position: absolute;
  bottom: 0;
  left: 0px;
  background-color: #ddd100;
  z-index: 1;
  padding: 2px 10px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.ortho-highlight-products .splide__slide:nth-child(1) .productitem__image:before
{
    content:"Nr. 1";
}
.ortho-highlight-products .splide__slide:nth-child(2) .productitem__image:before
{
    content:"Nr. 2";
}
.ortho-highlight-products .splide__slide:nth-child(3) .productitem__image:before
{
    content:"Nr. 3";
}
.ortho-highlight-products .splide__slide:nth-child(4) .productitem__image:before
{
    content:"Nr. 4";
}
.ortho-highlight-products .splide__slide:nth-child(5) .productitem__image:before
{
    content:"Nr. 5";
}
.ortho-highlight-products .splide__slide:nth-child(6) .productitem__image:before
{
    content:"Nr. 6";
}
.ortho-highlight-products .splide__slide:nth-child(7) .productitem__image:before
{
    content:"Nr. 7";
}
.ortho-highlight-products .splide__slide:nth-child(8) .productitem__image:before
{
    content:"Nr. 8";
}
.ortho-highlight-products .splide__slide:nth-child(9) .productitem__image:before
{
    content:"Nr. 9";
}
.ortho-highlight-products .splide__slide:nth-child(10) .productitem__image:before
{
    content:"Nr. 10";
}

.ortho-highlight-products .splide__arrows
{
  position:relative;
  bottom: -30px;
}

.ortho-highlight-products .splide__arrows .splide__arrow--prev
{
  bottom: 0;
  left: 0;
}

.ortho-highlight-products .splide__arrows .splide__arrow--next
{
  bottom: 0;
  left: 40px;
}

.ortho-usps 
{
  z-index: 1;
  position: relative;
  padding: 20px 20px 5px 20px;
}
.ortho-usps ul 
{
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
.ortho-usps ul li 
{
  display: inherit;
}
.ortho-usps ul li a
{
  text-decoration:none;
}
.ortho-usps ul li:before
{
  content: '\f00c';
  width: 1.8em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #ddd100;
}
.ortho-rtdusps
{
  border-top: 1px solid rgba(0,0,0,.125);
  border-bottom: 1px solid rgba(0,0,0,.125);
  padding:10px 0;
  margin:25px 0;
}

.ortho-rtdusps .col-auto:before
{
  content: '\f00c';
  width: 1.8em;
  font-size: 16px;
  line-height: 28px;
  font-family: FontAwesome;
  display: table-cell;
  padding-left: 7px;
  color: #ddd100;
}

.logo__carousel
{
  padding: 30px 0;
  background-color: #faf9ed;
}
.logo-slider .splide__track .splide__slide img
{
  max-height: 100px;
  width: auto!important;
}
.logo-slider .splide__list {
   margin: 0 -10px;
  align-items: center;
}

/* ========================================================================

   Verlanglijstje

*/

/* toon verlanglijstje verwijdering alleen op verlanglijst pagina */
html:not(.ortho--verlanglijstje) .ortho--only-verlanglijstje
{
  display:none;
}
.ortho__removefromlist label
{
  cursor:pointer;
}
.ortho__removefromlist .webshop-product__favorite
{
  visibility: hidden;
  width:0;
}
.ortho__removefromlist__text
{
  margin-left:5px;
}
