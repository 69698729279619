@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,400i,700&display=swap");
.webshop--isoutofstock .value_button,
.webshop--isoutofstock .product__quantitydiscounts {
  display: none;
}
.webshop--isoutofstock .webshop-product__add:hover {
  cursor: not-allowed;
}

.product__top {
  position: relative;
}
.product__top:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 40px #fff;
  border-left: solid 50px transparent;
  border-right: solid 50px transparent;
}

.lightbox .btn-close svg {
  fill: #000;
}
.lightbox .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.lightbox .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.lightbox .lightbox-carousel .carousel-inner .carousel-item .ratio {
  background-color: #fff !important;
}

@media (max-width: 576px) {
  #product__images__preview__container {
    width: 75%;
    float: left;
    border-right: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.product__images__grid {
  margin-top: 10px;
  margin-bottom: 15px;
  max-height: 130px;
  position: relative;
}
.product__images__grid .splide__slide {
  opacity: 0.5;
  cursor: pointer;
  border: 2px solid transparent;
}
.product__images__grid .splide__slide.is-active {
  opacity: 1;
  border: 2px solid #014268 !important;
}
@media (max-width: 576px) {
  .product__images__grid {
    width: 24%;
    float: right;
    max-height: inherit;
  }
  .product__images__grid .splide__arrows--ttb .splide__arrow {
    top: inherit;
    transform: none;
  }
  .product__images__grid .splide__arrows--ttb .splide__arrow--prev {
    bottom: 5px;
    left: 0;
    top: inherit;
  }
  .product__images__grid .splide__arrows--ttb .splide__arrow--next {
    bottom: 5px;
    top: inherit;
  }
}

.product_social_sharing {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
@media (max-width: 576px) {
  .product_social_sharing {
    right: 25%;
  }
}
.product_social_sharing .btn-link {
  color: #014268;
}

.product__info .btn-lg {
  padding: 0.7rem 1.3rem;
  font-size: 1rem;
}
@media (max-width: 360px) {
  .product__info .btn-lg {
    padding: 0.7rem 1rem;
  }
}
.product__info h1,
.product__info .product__maintext h2 {
  font-size: 28px;
}
.product__info h1:after,
.product__info .product__maintext h2:after {
  display: none;
}
.product__info .card {
  border: none;
  border-radius: 0;
}
.product__info .card .webshop-product__optionlabel {
  font-weight: 600;
}
.product__info #amount_container {
  max-width: 146px;
}
.product__info .in-decrease_amount {
  margin-bottom: 10px;
  margin-right: 10px;
  display: inline-block;
}
.product__info .in-decrease_amount .value_button {
  display: inline-block;
  border: 1px solid #ddd;
  margin: 0px;
  width: 45px;
  height: 49px;
  text-align: center;
  vertical-align: middle;
  padding: 12px 0;
  background: #faf8f5;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.product__info .in-decrease_amount .value_button:hover {
  cursor: pointer;
}
.product__info .in-decrease_amount #decrease {
  border-right: none;
  margin-top: -4px;
  border-radius: 0.3rem 0 0 0.3rem;
}
.product__info .in-decrease_amount #increase {
  border-left: none;
  margin-top: -4px;
  border-radius: 0 0.3rem 0.3rem 0;
}
.product__info .in-decrease_amount input#amount {
  text-align: center;
  border: none;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0px;
  width: 45px;
  height: 49px;
}
.product__info .in-decrease_amount input#amount::-webkit-inner-spin-button, .product__info .in-decrease_amount input#amount::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.product__discountslogan,
.product__stock__text {
  color: #75b325;
  font-weight: 700;
}

.product__stock__text span {
  font-weight: 400;
}

.product__stock__text.text-danger {
  font-weight: 500;
}

.product__freeproduct__text {
  font-size: 85%;
  line-height: 21px;
  color: #678196;
}
@media (max-width: 576px) {
  .product__freeproduct__text {
    display: none;
  }
}

.product__image img {
  max-height: 100px;
  width: auto;
}

.product_promoimage > img {
  max-width: 100%;
  height: auto;
}

.product__description {
  background-color: #faf9ed;
  margin-left: -15px;
  margin-right: -15px;
  padding: 70px 0;
}
@media (min-width: 768px) {
  .product__description aside {
    position: sticky;
    top: 50px;
  }
}
.product__description aside .product__widget__body {
  background-color: #fff;
  padding: 20px;
}
.product__description aside .product__widget__bg {
  background: url(../../web/img/banner-product-widget-bg.jpg) no-repeat 100% 0 #fff;
  padding: 20px;
}
.product__description aside .product__widget__bg h2 {
  color: #fff;
  font-size: 24px;
}
.product__description aside .product__widget__bg h2:after {
  display: none;
}

@media (max-width: 576px) {
  .product__amount_stockinfo {
    position: fixed;
    bottom: 0;
    z-index: 1;
    background-color: #faf9ed;
    width: 100%;
    padding: 10px 10px 50px 10px;
    left: 0;
    right: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.product__tags {
  margin-top: 20px;
  margin-bottom: 20px;
}

.product__tag {
  background: transparent;
  padding: 10px 15px;
  display: inline-block;
  margin: 5px;
  color: #014268;
  border-radius: 0px;
  font-size: 13px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out 0s;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-weight: 400;
  text-decoration: none;
}

.product__tag:hover {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  text-decoration: none;
  border: 1px solid #014268;
  color: inherit;
}

.product__related {
  margin-top: 15px;
}

.webshop-product__priceblock {
  display: inline-block;
}

.webshop-product__listprice {
  font-size: 19px;
  font-weight: 400;
  display: inline-block;
  position: relative;
}
.webshop-product__listprice:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid red;
  border-radius: 0;
  transform: rotate(-11deg);
}

.webshop-product__price {
  font-size: 28px;
  font-weight: 600;
}

input.ortho__favorite {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}
input.ortho__favorite + label {
  margin: 0;
}
input.ortho__favorite:checked + label > .ortho--hide-if-favorite, input.ortho__favorite:not(:checked) + label > .ortho--show-if-favorite {
  display: none;
}