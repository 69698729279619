@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,400i,700&display=swap");
.categoryintro #categoryintro_collapse.collapse:not(.show) {
  display: block;
}
.categoryintro .btn-link.collapsed:after {
  content: "+ Lees meer";
}
.categoryintro .btn-link:not(.collapsed):after {
  content: "- Lees minder";
}
@media (max-width: 767px) {
  .categoryintro #categoryintro_collapse.collapse:not(.show) {
    display: block;
    height: 135px;
    overflow: hidden;
  }
  .categoryintro #categoryintro_collapse.collapsing {
    height: 3rem;
  }
}

#product_listing {
  margin-bottom: 50px;
}

.productsgrid__product--hidden {
  display: none;
}

.page--categories main {
  margin-bottom: 50px;
}

.page--categories .breadcrumb .widget-fullwidthimage img {
  border-radius: 0.25rem;
}

.product__related .productsgrid .productitem__baseprice > .price,
.combinesection__products .productsgrid .productitem__baseprice > .price {
  font-size: 16px;
}
.product__related .productsgrid .productitem__nowprice > span,
.combinesection__products .productsgrid .productitem__nowprice > span {
  font-size: 21px;
}

.card-columns .card {
  margin-bottom: 1.75rem;
}

.productsgrid a, .productsgrid a p, .productsgrid a:hover p,
.categorygrid a,
.categorygrid a p,
.categorygrid a:hover p {
  text-decoration: none;
}
.productsgrid a:hover,
.categorygrid a:hover {
  text-decoration: none;
}

@media (max-width: 576px) {
  .productsgrid__row {
    margin-right: 0;
    margin-left: 0;
  }
  .productsgrid__row > .col,
  .productsgrid__row > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
  }
  .productsgrid__row > .col .card-footer .btn,
  .productsgrid__row > [class*=col-] .card-footer .btn {
    margin-top: 10px;
  }
}
@media (max-width: 785px) {
  .productsgrid__row > .col .card-body,
  .productsgrid__row > [class*=col-] .card-body {
    display: flex;
    justify-content: space-between;
  }
  .productsgrid__row > .col .card-body .productitem__image,
  .productsgrid__row > [class*=col-] .card-body .productitem__image {
    min-height: 150px;
    width: 40%;
    margin-bottom: 0;
  }
  .productsgrid__row > .col .card-body .productitem__content,
  .productsgrid__row > [class*=col-] .card-body .productitem__content {
    width: 58%;
  }
}

.productitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.3s ease;
  border: none;
}
.productitem:hover, .productitem:focus, .productitem.ribbon-beste-pakket, .productitem.ribbon-nieuwjaarsdeal, .productitem.ribbon-actie {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: inherit;
}
.productitem.ribbon:before {
  width: 124px;
  height: 124px;
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  pointer-events: none;
}
@media (max-width: 785px) {
  .productitem.ribbon:before {
    width: 100px;
    height: 100px;
    left: 0;
    transform: rotate(270deg);
  }
}
.productitem.ribbon-beste-pakket:before {
  background: url("../../web/img/ribbons/beste-pakket.png") no-repeat;
}
.productitem.ribbon-black-friday:before {
  background: url("../../web/img/ribbons/black-friday.png") no-repeat;
}
.productitem.ribbon-nieuwjaarsdeal:before {
  background: url("../../web/img/ribbons/nieuwjaarsdeal.png") no-repeat;
}
.productitem.ribbon-actie:before {
  background: url("../../web/img/ribbons/actie.png") no-repeat;
}
.productitem .productitem__image {
  text-align: center;
  margin-bottom: 10px;
  position: relative;
  margin-left: -8px;
  margin-right: -8px;
  font-weight: 600;
}
.productitem .card-body {
  padding: 0.5rem;
}
.productitem .card-body .productitem__description {
  min-height: 100px .productitem .card-body .productitem__description p;
  min-height-margin-bottom: 0;
}
.productitem .card-footer {
  position: relative;
  background-color: #f8f9fa;
}
@media (max-width: 576px) {
  .productitem .card-footer {
    border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
  }
}
.productitem__title {
  font-size: 21px;
  padding-bottom: 5px;
  margin-bottom: 0;
}
.productitem__image {
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.productitem__image img {
  align-self: center;
}
.productitem__rating {
  display: flex;
  align-items: center;
}
.productitem__discountslogan {
  color: #7fb204;
  font-weight: 600;
}
.productitem__baseprice > .price {
  display: inline-block;
  color: #678196;
  font-size: 18px;
  font-weight: 400;
  position: relative;
}
.productitem__baseprice > .price:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 0px;
  width: 100%;
  height: 9px;
  background: transparent;
  border-top: 1px solid red;
  border-radius: 0;
  transform: rotate(-11deg);
}
.productitem__baseprice + .productitem__nowprice {
  padding-top: 2px;
}
.productitem__nowprice > span {
  display: inline-block;
  font-size: 32px;
  font-weight: 400;
  color: #014268;
}
@media (max-width: 565px) {
  .productitem__nowprice > span {
    margin-bottom: 10px;
  }
}
.productitem__baseprice > span.pricemultiple, .productitem__nowprice > span.pricemultiple {
  font-size: inherit;
  font-weight: inherit;
}
.productitem .btn {
  font-size: 16px;
  margin-top: 40px;
}
.productitem__message {
  color: #4aa814;
  font-weight: 600;
}

@media (max-width: 767px) {
  .categorygrid {
    display: none;
  }
}
.categoryitem {
  font: inherit;
  cursor: pointer;
  transition: all 0.1s ease-in-out 0s;
  border: none;
  padding-bottom: 20px;
  border-bottom: 2px solid #eee;
}
.categoryitem:hover, .categoryitem:focus {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: inherit;
}
.categoryitem .productitem__image {
  min-height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.categoryitem .productitem__image img {
  align-self: center;
}
.categoryitem .card-body {
  padding: 0.5rem;
}
.categoryitem .card-footer {
  position: relative;
  border-top: 0;
  background-color: rgba(0, 0, 0, 0);
  text-align: center;
}
.categoryitem__title {
  font-size: 21px;
  padding-bottom: 5px;
  text-align: center;
}
.categoryitem .btn {
  text-align: center;
}