@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,400i,700&display=swap");
@import "~dompack/browserfix/reset.css";
html {
  background-color: #fff;
}

body {
  background: #fff;
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-attachment: fixed;
  overflow-x: hidden;
}

body, main.site-wrapper {
  min-height: 100vh;
}

.lead {
  font-size: 1.2rem;
  font-weight: 300;
}

@media (min-width: 1920px), (min-height: 1200px) {
  body {
    background-size: cover;
  }
}
.main--margintop {
  margin-top: 1.5rem;
}
@media (max-width: 767px) {
  .main--margintop {
    margin-top: 0.5rem;
  }
}

.wh-rtd__img {
  border-radius: 0.25rem;
}

.site__content {
  flex: 1 1 auto;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 0.2rem;
  color: #a89895;
}

main > nav {
  margin-top: -10px;
}

main .pageimage {
  margin: 0 -20px 20px;
}
main .pageimage > img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
}

html.page--blogitem main .pageimage {
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
}

.contents--spaceright {
  padding-right: 30%; /* 825 */
  position: relative;
}

.contents__imageright {
  width: calc(30% - 20px);
  min-height: 20px;
  position: absolute;
  top: 7px;
  right: 0;
}
.contents__imageright > img {
  width: 100%;
  height: auto;
}

@media (max-width: 767px) {
  .contents--spaceright {
    padding-right: 0;
  }
  .contents__imageright {
    display: none;
  }
}
.webshop-checkout {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}
.rating-stars {
  background-image: url(web/img/stars-grey.png);
  display: inline-block;
  height: 22px;
  position: relative;
  width: 117px;
}
.rating-stars > .inner {
  background-image: url(web/img/stars-gold.png);
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.pagetags {
  margin: 30px 0;
}
.pagetags ul {
  list-style-type: none;
  margin: 0 0 0 -6px;
  padding: 0;
}
.pagetags ul li {
  display: inline-block;
  margin-bottom: 6px;
  margin-left: 6px;
}
.pagetags ul li a {
  display: inline-block;
  color: #b5aba7;
  border-color: #b5aba7;
  padding: 6px 12px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  transition: background-color 0.2s, border-color 0.2s;
  border: 1px solid transparent;
}
.pagetags ul li a:hover {
  background-color: #fff;
  border-color: #584a45;
}

.breadcrumb {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.breadcrumb li {
  display: inline-block;
}

.breadcrumb a {
  color: #ccc;
}

.breadcrumb-item + .breadcrumb-item:before {
  color: #ccc;
}

.contentarea {
  position: relative;
  z-index: 0;
}

button {
  cursor: pointer;
}

.btn {
  cursor: pointer;
  white-space: normal;
}

.btn-lg {
  font-size: 1rem;
}

.btn-primary {
  background-color: #7fb204;
  border-color: #7fb204;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-primary:hover,
.btn-primary:focus {
  background-color: #90cc04;
  border-color: #90cc04;
  text-decoration: none;
}

.btn-primary:active,
.btn-primary.active,
.btn-primary.active:hover {
  background-color: #90cc04;
  border-color: #90cc04;
}

.btn-secondary {
  background-color: #0273b5;
  border-color: #0273b5;
  color: #fff !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #0284cf;
  border-color: #0284cf;
  text-decoration: none;
}

.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.active:hover {
  background-color: #0284cf;
  border-color: #0284cf;
}

.btn-outline-secondary {
  background-color: #fff;
  border-color: #0273b5;
  color: #0273b5;
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #0273b5;
  border-color: #0273b5;
  text-decoration: none;
}

.btn-success {
  background-color: rgb(68, 187, 110);
  border-color: rgb(68, 187, 110);
}

.btn-info {
  background-color: #4185d6;
}

.card-body {
  padding: 1rem;
}

.productitem .card-footer {
  overflow: hidden;
  padding: 0.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.productitem__holder {
  opacity: 1;
  transition: opacity 300ms;
}

.productitem__holder--fadeout {
  opacity: 0;
}

aside {
  background: #fff;
  margin: 0 0 20px;
  padding: 20px;
  overflow: hidden;
  background-color: #f8f9fa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

aside#sidenav {
  position: relative;
  z-index: 1;
}

aside .aside__title {
  font-size: 18px;
  color: #014268;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 1.1;
  display: block;
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: 600;
  line-height: 1.1;
}

aside.usps .aside__title {
  display: block;
  padding: 20px 20px 10px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 26px;
  line-height: 1.1;
  text-align: center;
  background: url(web/img/prodsmall-footer.gif) repeat-x 0 100%;
}

aside.usps ul li:first-child {
  position: relative;
  list-style-type: none;
  background: url(web/img/prodsmall-footer.gif) repeat-x 0 100%;
  padding: 8px 20px 8px 50px;
}

aside.usps span.aside__title small {
  text-transform: none;
  color: #014268;
  font-size: 21px;
  font-weight: 400;
}

aside.usps ul {
  display: block;
  margin: 0;
  padding: 0;
}

aside.usps ul li:first-child {
  font-weight: 600;
  font-size: 16px;
}

aside.usps ul li {
  position: relative;
  list-style-type: none;
  padding: 8px 20px 8px 50px;
}

aside.usps ul li b {
  font-size: 16px;
}

aside.usps ul li a {
  color: #014268;
}

aside.usps ul li:before {
  content: "\f00c";
  position: absolute;
  left: 20px;
  font-family: fontAwesome;
  color: #75b325;
  font-size: 21px;
  top: 10px;
}

aside .mm-rating,
.reviewtotals .mm-rating {
  display: block;
  font-size: 23px;
  margin: 10px 0 -3px -2px;
}

aside .mm-recensie,
.reviewtotals .mm-recensie {
  background: #014268;
  border-radius: 30px;
  color: #fff;
  text-align: center;
  width: 50px;
  height: 50px;
  float: left;
  margin-right: 5px;
}

aside .mm-rating-txt,
aside .mm-rating-txt a,
.reviewtotals .mm-rating-txt,
.reviewtotals .mm-rating-txt a {
  color: #014268;
  display: inline-block;
  font-size: 14px;
  margin: 0;
}

.mydialog {
  background: white;
  padding: 20px;
}
.mydialog__modalbg {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  white-space: nowrap;
  vertical-align: middle;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mydialog__modalbg:before {
  display: inline-block;
  content: "";
  vertical-align: middle;
  line-height: 100%;
  height: 100%;
  width: 0;
}
.mydialog__holder {
  text-align: left;
  display: inline-block;
  white-space: normal;
  font-family: inherit;
  color: inherit;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

#sidenav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#sidenav ul ul li.active a {
  font-weight: 600;
  text-decoration: underline;
}
#sidenav > ul {
  margin-top: -5px;
}
#sidenav li {
  display: block;
}
#sidenav a {
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 150%;
  color: #014268;
}
#sidenav a:hover {
  text-decoration: underline;
}
#sidenav > ul > li > a {
  font-weight: 600;
  font-size: 18px;
  padding: 7px 0;
  color: #014268;
}
#sidenav > ul > li > ul > li li {
  padding-left: 15px;
}
#sidenav li > ul a {
  padding: 5px 0 3px 10px;
  position: relative;
}

.contents__catpath {
  list-style: none;
  margin-bottom: 10px;
  padding-left: 0;
  color: #a89895;
}

.contents__catpathelt {
  display: inline-block;
}

.contents__catpathelt + li::before {
  padding: 0 5px;
  color: #ccc;
  content: "/ ";
}

.summary .pagedescription {
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 30px;
}
.summary .card:hover, .summary .card:focus {
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}
.summary .card:hover .card-body .labels a, .summary .card:focus .card-body .labels a {
  color: #fff;
}
.summary .card:hover .card-body a, .summary .card:focus .card-body a {
  text-decoration: none;
  color: inherit;
}
.summary h2 {
  font-size: 24px;
  margin-bottom: 0;
}
.summary .labels {
  position: relative;
  height: 5px;
  bottom: 30px;
}
.summary .labels__label {
  display: block;
  width: fit-content;
  background-color: rgba(1, 66, 104, 0.8);
  color: #fff;
  padding: 3px 12px;
  font-size: 13px;
  margin-bottom: 5px;
}
.summary .blog--compact .description {
  display: none;
}
.summary .blog--compact .card-body {
  margin-bottom: 0 !important;
  padding-bottom: 0;
}
.summary .blog--compact .card-footer {
  background-color: #fff;
  padding-top: 0;
  border-top: 0;
}
.summary .blog--compact .card-footer .btn {
  color: #014268 !important;
  background-color: #fff !important;
  padding: 0;
  border-color: transparent !important;
  box-shadow: none;
  text-shadow: none;
  float: none !important;
}

.webshop-account__orders {
  width: 100%;
  margin: 0 0 20px;
  border-bottom: 1px solid #f0f0f0;
}
.webshop-account__orders .webshop-account__ordertotal:before {
  display: inline-block;
  content: "€";
  padding-right: 5px;
}
.webshop-account__orders td, .webshop-account__orders th {
  padding: 5px 20px 5px 0;
  white-space: nowrap;
}
.webshop-account__orders .webshop-account__ordertotal {
  text-align: right;
  width: 100px;
}
.webshop-account__orders .webshop-account__orderrow:nth-child(2n-1) td {
  background-color: #fafafa;
}

.webshop-account__fullbillingaddress .webshop-account__ordertotal:before {
  content: "€ ";
}

.btn-default {
  color: #999;
  border-color: #ccc;
  transition: all 0.5s;
}

.btn-default.btn-outline:hover {
  background-color: #d9534f;
  color: #fff;
  border-color: #d9534f;
}

.submenu:not(.d-none) {
  display: none;
}

/* mobile left sidemenu: */
#leftSidebar,
#filterSideNav {
  border-top: 0;
  background-color: #014268;
  max-width: 320px;
}
#leftSidebar aside,
#filterSideNav aside {
  background-color: transparent;
  padding: 0;
}
#leftSidebar .aside__title,
#leftSidebar .productfilter-title,
#leftSidebar .productfilter--optionlist li label,
#leftSidebar .productfilter__activetitle,
#leftSidebar .wh-slider-holder > .wh-slider > .wh-slider-knob,
#leftSidebar h3,
#leftSidebar .nav-link,
#filterSideNav .aside__title,
#filterSideNav .productfilter-title,
#filterSideNav .productfilter--optionlist li label,
#filterSideNav .productfilter__activetitle,
#filterSideNav .wh-slider-holder > .wh-slider > .wh-slider-knob,
#filterSideNav h3,
#filterSideNav .nav-link {
  color: #fff !important;
}
#leftSidebar .productfilter--select,
#filterSideNav .productfilter--select {
  border: 0;
}
#leftSidebar .close-btn,
#filterSideNav .close-btn {
  width: 42px;
  height: 42px;
  display: block;
  float: right;
  width: 42px;
  height: 41px;
  display: block;
  padding: 5px 5px;
}
#leftSidebar .close-btn:before,
#filterSideNav .close-btn:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #fff;
  display: block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  transform: translateY(-1px) rotate(45deg);
}
#leftSidebar .close-btn:after,
#filterSideNav .close-btn:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  margin-top: -2px;
  background: #fff;
  display: block;
  -webkit-transform: translateY(-1px) rotate(-90deg);
  transform: translateY(-1px) rotate(-45deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#leftSidebar .webshop-search input,
#leftSidebar .input-group > .input-group-append > .btn,
#filterSideNav .webshop-search input,
#filterSideNav .input-group > .input-group-append > .btn {
  border: none;
  border-radius: 0;
}
#leftSidebar ul,
#filterSideNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#leftSidebar ul li,
#filterSideNav ul li {
  border-top: 1px solid #015382;
  border-bottom: 1px solid #014b75;
  margin-top: -1px;
}
#leftSidebar ul li a,
#filterSideNav ul li a {
  font-size: 14px;
}
#leftSidebar ul li ul li,
#filterSideNav ul li ul li {
  border: none;
}
#leftSidebar .has-submenu,
#filterSideNav .has-submenu {
  position: relative;
}
#leftSidebar .has-submenu .nav-link,
#filterSideNav .has-submenu .nav-link {
  display: block;
  margin-right: 42px;
  font-size: 14px;
}
#leftSidebar .has-submenu > .submenu-toggle,
#filterSideNav .has-submenu > .submenu-toggle {
  position: absolute;
  right: 0;
  top: -1px;
  z-index: 1;
  display: block;
  float: right;
  width: 41px;
  height: 42px;
  border-top: 1px solid #015382;
  border-left: 1px solid #015382;
  border-bottom: 1px solid #015382;
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  box-sizing: border-box;
}
#leftSidebar .has-submenu > .submenu-toggle:last-child,
#filterSideNav .has-submenu > .submenu-toggle:last-child {
  border-top: none;
}
#leftSidebar .has-submenu > .submenu-toggle:before,
#filterSideNav .has-submenu > .submenu-toggle:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #fff;
  display: block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#leftSidebar .has-submenu > .submenu-toggle:after,
#filterSideNav .has-submenu > .submenu-toggle:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  background: #fff;
  display: block;
  -webkit-transform: translateY(-1px) rotate(-90deg);
  transform: translateY(-1px) rotate(-90deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#leftSidebar .has-submenu > .submenu-toggle:hover,
#filterSideNav .has-submenu > .submenu-toggle:hover {
  cursor: pointer;
}
#leftSidebar .has-submenu > .submenu-toggle.collapse--open:before,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#leftSidebar .has-submenu > .submenu-toggle.collapse--open:after,
#filterSideNav .has-submenu > .submenu-toggle.collapse--open:after {
  -webkit-transform: translateY(-1px) rotate(-360deg);
  transform: translateY(-1px) rotate(-360deg);
  opacity: 0;
}
#leftSidebar ul li.active,
#filterSideNav ul li.active {
  border-top: 0;
}
#leftSidebar ul.submenu,
#filterSideNav ul.submenu {
  margin-left: 0.5rem;
}
#leftSidebar .btn-link,
#leftSidebar .btn-link:active,
#leftSidebar .btn-link:focus,
#leftSidebar .btn-link:hover,
#filterSideNav .btn-link,
#filterSideNav .btn-link:active,
#filterSideNav .btn-link:focus,
#filterSideNav .btn-link:hover {
  border-color: hsla(0, 0%, 100%, 0.5);
  border-radius: 5px;
}

@media (min-width: 1200px) {
  #leftSidebar {
    display: none;
  }
}
.z-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.z-depth-2 {
  -webkit-box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-3 {
  -webkit-box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.z-depth-4 {
  -webkit-box-shadow: 0 0px 60px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 0px 60px 0 rgba(0, 0, 0, 0.22);
}

.z-depth-5 {
  -webkit-box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2), 0 40px 77px 0 rgba(0, 0, 0, 0.22);
}