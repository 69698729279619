/* The part of the form controlled by the alternative interaction needs to be hidden */
.ortho-shippingmethod__formpart {
  display: none;
}

/* All the stuff that needs to be hidden when address is not NL */
.ortho-shippingmethod__nlspecific--hidden {
  display: none;
}

.ortho-checkout__addcode,
.ortho-checkout__addloyaltypoints {
  height: 0;
  display: flex;
  overflow: hidden;
  transition: height 300ms;
}

input.webshop-checkout__addcouponcode {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  height: 30px;
  font: inherit;
  padding: 5px 10px;
  max-width: 300px;
  -webkit-appearance: none;
}

.webshop-checkout__addcouponbutton {
  border-radius: 0 3px 3px 0;
}

#orderform-loyaltypoints {
  max-width: 145px;
}

.webshop-checkout__loyaltysection {
  max-width: 285px;
}

.ortho-checkout__addloyaltypoints .wh-form__suffix {
  position: absolute;
  right: 0;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__cost {
  display: none;
}

.webshop-checkout__shippingmethod.webshop-checkout--isfree .webshop-checkout__costs:after {
  content: "gratis";
  color: #5cb85c;
  font-weight: 600;
}

.webshop-checkout__paymentmethod.webshop-checkout--isfree .webshop-checkout__costs {
  display: none;
}

.wh-form__label[for=orderform-wrd_lastname],
.wh-form__label[for="orderform-billing_address.nr_detail"],
.wh-form__label[for="orderform-billing_address.city"],
.wh-form__label[for="orderform-shipping_address.nr_detail"],
.wh-form__label[for="orderform-shipping_address.city"] {
  visibility: hidden;
  width: 0;
}

.wh-form__label[for="orderform-billing_address.zip"],
.wh-form__label[for="orderform-shipping_address.zip"] {
  white-space: nowrap;
}

.ortho-shippingmethod__nloption:not(:first-child) {
  display: none;
}

html.wh-wrdauth--isloggedin .ortho-checkout__loginline {
  display: none;
}

html:not(.ortho--showcompanyfields) .ortho-checkout__inlinecompanyfields {
  display: none;
}

html:not(.ortho--showrepeatedcart) .ortho-checkout__repeatedcart {
  display: none;
}

.ortho-shippingmethod__costs__postnlfinal {
  display: none;
}

.ortho-wh-form__fields {
  border-top: 1px solid #ddd;
  align-items: stretch;
  flex-direction: row;
  position: relative;
  vertical-align: bottom;
}
.ortho-wh-form__fields > .wh-form__label {
  display: none;
}
.ortho-wh-form__fields > * {
  padding: 0;
  min-height: 40px;
  display: flex;
}
.ortho-wh-form__fields > *.webshop-checkout__costs {
  display: block;
  position: absolute;
  padding-top: 5px;
  top: 0;
  right: 5px;
  pointer-events: none;
}
.ortho-wh-form__fields label {
  margin-bottom: 0;
  cursor: pointer;
}
.ortho-wh-form__fields input + label {
  flex: 0 0 30px;
  position: relative;
}
.ortho-wh-form__fields input + label:before {
  content: "";
  width: 30px;
  position: absolute;
  top: 7px;
  left: 5px;
  display: inline-block;
  font-family: fontAwesome;
  color: #5cb85c;
  font-size: 26px;
}
.ortho-wh-form__fields input:checked + label:before {
  content: "\f00c";
  background: none;
}
.ortho-wh-form__fields input + label:before {
  background: url(../../web/img/radiobutton.png) no-repeat 1px -2px;
  width: 30px;
  background-size: 24px;
  height: 30px;
}
.ortho-wh-form__fields input:checked ~ * {
  background-color: #ecf9ff;
}
.ortho-wh-form__fields .wh-form__optiondata {
  width: 0;
  padding: 0;
  min-height: 40px;
  display: flex;
}
.ortho-wh-form__fields .wh-form__optiondata > label {
  width: 100%;
  height: 100%;
  max-height: 60px; /* IE fix, else IE has render problems with height on mouseover */
  padding: 5px 5px 5px 50px;
  display: block;
  position: relative;
  font-weight: 600;
}
.ortho-wh-form__fields .wh-form__optiondata > label:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 2px;
  width: 38px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: 0 2px;
}

.webshop-checkout__shippingmethods .ortho-shippingmethod__postnl .wh-form__optiondata > label:before {
  background-image: url(../../web/img/shipping/postnl.png);
}

.webshop-checkout__shippingmethods .ortho-shippingmethod__retrieve .wh-form__optiondata > label:before {
  background-image: url(../../web/img/shipping/afhalen.png);
}

.ortho-shippingmethod__postnl {
  position: relative;
  height: 95px;
}

.ortho-shippingmethod__nlspecific {
  position: absolute;
  left: 40px;
  top: 38px;
}

.ortho-shippingmethod__retrieve:hover,
.ortho-shippingmethod__postnl:hover {
  background-color: #ecf9ff;
}

.ortho-shippingmethod__postnl .ortho-shippingmethod__nloptioninput {
  margin-right: 5px;
}

.ortho-shippingmethod__postnl .webshop-checkout__cost {
  margin-left: 5px;
}

.webshop-checkout__costs,
.webshop-listcart__total {
  padding-right: 5px;
}

.webshop-checkout__loyaltysection {
  position: relative;
}

.webshop-checkout__loyaltysection .wh-form__fieldline {
  align-items: center;
}

.webshop-checkout__loyaltysection .wh-form__label {
  display: none !important;
}