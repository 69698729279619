@media (max-width: 767px) {
  html.webshop--iscrosssellpage h1 {
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  html.webshop--iscrosssellpage h2 {
    font-size: 22px;
  }
}
@media (max-width: 992px) {
  html.webshop--iscrosssellpage #sidebar {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
}
@media (max-width: 576px) {
  html.webshop--iscrosssellpage #sidebar aside {
    padding: 10px;
  }
}
html.webshop--iscrosssellpage .webshop-addedcart {
  background-color: #faf9ed;
  position: relative;
  margin-bottom: 0;
}
html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__imageholder {
  padding: 10px;
}
html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__imageholder > img {
  width: 100%;
  height: auto;
}
@media (max-width: 992px) {
  html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__imageholder {
    display: none;
  }
}
html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__titleholder {
  padding: 10px 0;
}
@media (max-width: 992px) {
  html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__titleholder {
    padding: 0;
  }
}
@media (max-width: 992px) {
  html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__priceholder .btn {
    display: inline-block !important;
  }
  html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__priceholder .btn i {
    display: none;
  }
}
html.webshop--iscrosssellpage .webshop-addedcart .webshop-addedcartproduct__priceholder .addedproduct__price {
  display: block;
  font-size: 30px;
  line-height: 140%;
  padding-bottom: 10px;
}
html.webshop--iscrosssellpage div[webshop-crosssell__group] .webshop-crosssell__description h2,
html.webshop--iscrosssellpage div[webshop-crosssell__group] .webshop-crosssell__description h3 {
  margin-bottom: 0;
  padding-bottom: 0;
}
@media (max-width: 576px) {
  html.webshop--iscrosssellpage div[webshop-crosssell__group] .webshop-crosssell__description ol,
  html.webshop--iscrosssellpage div[webshop-crosssell__group] .webshop-crosssell__description li,
  html.webshop--iscrosssellpage div[webshop-crosssell__group] .webshop-crosssell__description p {
    font-size: 14px;
    line-height: 21px;
  }
}
@media (max-width: 767px) {
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssell__row {
    margin-right: -5px;
    margin-left: -5px;
  }
}
@media (max-width: 767px) {
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__image {
    height: 100px;
    min-height: 100px;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__image img {
    align-self: unset;
    height: inherit;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__title {
    font-size: 16px;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__description {
    display: none;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__baseprice {
    font-size: 12px;
    color: #678196;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__nowprice > span {
    font-size: 24px;
    margin-bottom: 0;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__baseprice + .productitem__nowprice {
    line-height: 16px;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem__discountslogan {
    font-size: 12px;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem[class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 10px !important;
  }
  html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .btn {
    margin-top: 3px;
    display: block;
    width: 100%;
    font-size: 12px;
  }
}
html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem--hidden {
  display: none;
}
html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem {
  display: block;
}
html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .productitem .webshop-product__add {
  width: 100%;
  background-color: transparent;
}
html.webshop--iscrosssellpage div[webshop-crosssell__group] div[webshop-crosssell__groupproducts] .crosssellitem .btn {
  margin-top: 3px;
}